import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Marlin's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">“In the past when I lost weight, I still thought of myself as fat so I always put the weight back on. Now, I’ve lost 70 pounds and I’m keeping it off because I’ve changed my habits."</h1>
              <div className="div-block"><img width={74} src="/images/5ddc49cd315b3039bba6af51_13-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Marlin</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5dc9b53847ea21dec6288d6c_a-person-stepping-on-scales-to-see-if-they-have-hit-a-weight-loss-plateau.jpg" alt="" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Marlin</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>Weight Loss, Procrastination </div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>Lost 70 pounds</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>There were two issues Marlin wanted to change in his life: his weight and his habit of procrastination. While browsing in a bookstore, he discovered Effective Learning’s audio self-help program ‘Achieve Your Ideal Weight’. “I’d used many motivational tapes in the past to help me lose weight, but this one actually worked.” He lost a staggering 70 pounds.</p>
                  <p>“I’d listen to the program in the car. It’s one hour long, and I really liked listening to Deirdre Griswold. And then I started making changes to my behavior. Instead of eating dinner in front of the TV, I started to eat at the table. I used to eat really fatty food, too. Now, I make better choices and have more self-control. I also go walking for 3 miles every day.”</p>
                  <p>Marlin also describes the importance of changing his self-image. “You need to see yourself as thin. In the past when I lost weight, I still thought of myself as fat so I always put the weight back on. Now, I’ve lost 70 pounds and I’m keeping it off because I’ve changed my habits. Part of it is visualizing myself as thin. It really did help me lose weight.”</p>
                  <p>Following his weight loss success, Marlin tackled his unhelpful procrastination habit. “I used to sleep in until 10 o’clock every morning. Now, I’m up at 6. And I always used to put things off and tell myself I’d do it tomorrow.” Using the Conquering Procrastination program, Marlin learned important tools to help him deal with this issue. “Each morning, I write a list of what needs to be done and I get it done.” When asked what helped him the most, he mentions breaking tasks down into 15 minute blocks rather than feeling overwhelmed with big projects. “I’d had trouble with procrastination for 50 years, so this really was a breakthrough for me.”</p>
                  <p>Marlin believes what makes Effective Learning’s programs different is the way they change your brain. “You learn better habits. It teaches you how to create better habits to achieve your goals.” He’s since purchased other programs from Effective Learning including Discovering and Developing Your Talents and Freedom From Insomnia. Now, Marlin is writing a book and completes 15-20 pages a day. “I highly recommend these programs. They are well-priced and they work.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
